import G1 from './assets/1.jpeg';
import G2 from './assets/2.jpeg';
import G3 from './assets/3.jpeg';
import G4 from './assets/4.jpeg';

export const photos = [
  {
    original: G1,
    thumbnail: G1,
  },
  {
    original: G2,
    thumbnail: G2,
  },
  {
    original: G3,
    thumbnail: G3,
  },
  {
    original: G4,
    thumbnail: G4,
  },
];
